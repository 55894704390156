import { RouteConfig } from 'vue-router';
import Sets from '@/views/Sets/Sets.vue';
import analytics from './analytics';
import orders from './orders';
import affiliates from './affiliates';
import { getEtsyAccessToken } from '@/api/shopify/etsy';
import linkProduct from './linkProduct';
import products from './products';

const routes: RouteConfig[] = [
    {
        path: '/',
        component: () => import('@/views/Main.vue'),
        meta: { requireStore: true },
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import(/* webpackChunkName: "home" */ '@/views/Home/Home.vue')
            },
            {
                path: '/authError',
                name: 'Authentication Error',
                component: () => import(/* webpackChunkName: "authError" */ '@/views/AuthError.vue')
            },
            {
                path: '/settings',
                name: 'Settings',
                component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/Settings.vue')
            },
            {
                path: '/sets',
                name: 'Sets',
                component: Sets,
                beforeEnter: (_to, _from, next) => {
                    // Preload set page
                    import(/* webpackChunkName: "sets" */ '@/views/Set/Set.vue');
                    next();
                }
            },
            {
                path: '/set/:optionSetId?',
                name: 'Set',
                props: true,
                component: () => import(/* webpackChunkName: "set" */ '@/views/Set/Set.vue')
            },
            ...products,
            {
                path: '/podSelection',
                name: 'PODSelection',
                component: () => import(/* webpackChunkName: "podSelection" */ '@/views/Providers/Providers.vue')
            },
            {
                path: '/productsSelection/:productId?/:providerId?',
                name: 'ProductSelection',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "productSelection" */ '@/views/StoreProductSelection/StoreProductSelection.vue'
                    )
            },
            {
                path: '/printful',
                name: 'Printful',
                component: () => import(/* webpackChunkName: "printful" */ '@/views/Providers/Printful/Printful.vue')
            },
            {
                path: '/printify',
                name: 'Printify',
                component: () => import(/* webpackChunkName: "printify" */ '@/views/Providers/Printify/Printify.vue')
            },
            {
                path: '/manualPod/:podId',
                name: 'manualPod',
                component: () =>
                    import(/* webpackChunkName: "manualPod" */ '@/views/Providers/ManualProviders/ManualProviders.vue')
            },
            {
                path: '/productBuilder',
                name: 'ProductBuilder',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: "ProductBuilder" */ '@/views/Providers/ProductBuilder/ProductBuilder.vue'
                    )
            },
            {
                path: '/products_preview',
                name: 'Preview',
                component: () => import(/* webpackChunkName: "preview" */ '@/views/Preview/Preview.vue')
            },
            {
                path: '/reviewProducts/:productId?',
                name: 'ReviewProducts',
                component: () =>
                    import(/* webpackChunkName: "reviewProducts" */ '@/views/ReviewProducts/ReviewProducts.vue')
            },
            {
                path: '/designSelection',
                name: 'DesignSelection',
                component: () =>
                    import(/* webpackChunkName: "designSelection" */ '@/views/DesignSelection/DesignSelection.vue')
            },
            {
                path: '/designLibrary',
                name: 'DesignLibrary',
                component: () =>
                    import(/* webpackChunkName: "designLibrary" */ '@/views/DesignLibrary/DesignLibrary.vue')
            },
            {
                path: '/import-customily-designs',
                name: 'ImportCustomilyDesigns',
                component: () =>
                    import(
                        /* webpackChunkName: "importCustomilyDesigns" */ '@/views/ImportDesigns/CustomilyDesigns.vue'
                    )
            },
            {
                path: '/create/template/customily-designs',
                name: 'CreateTemplateCustomilyDesigns',
                meta: { importTemplateOnly: true },
                component: () =>
                    import(
                        /* webpackChunkName: "CreateTemplateCustomilyDesigns" */ '@/views/ImportDesigns/CustomilyDesigns.vue'
                    )
            }
        ]
    },
    ...analytics,
    ...affiliates,
    ...orders,
    ...linkProduct,
    {
        path: '/shPaymentConfirmed/:storeUrl',
        name: 'Shopify Payment Confirmed',
        component: () =>
            import(/* webpackChunkName: "paymentConfirmed" */ '@/views/PaymentConfirmed/PaymentConfirmed.vue'),
        meta: { isOpen: true }
    },
    {
        path: '/oauth-redirect',
        async beforeEnter(_to, _from, next) {
            const parameters = new URLSearchParams(window.location.search);
            const code = parameters.get('code')?.toString();
            const state = parameters.get('state')?.toString() || '';
            if (code) await getEtsyAccessToken(code, state);
            next({ name: 'PODSelection' });
        }
    },
    {
        path: '/canva-oauth-redirect',
        beforeEnter() {
            const parameters = new URLSearchParams(window.location.search);
            const code = parameters.get('code')?.toString() || '';

            window.opener.postMessage(
                {
                    code,
                    action: 'canva-authorization-completed'
                },
                `${process.env.VUE_APP_CUSTOMILY_BASE_URL}/createProduct1`
            );
        }
    }
];

export default routes;
