import { RouteConfig } from 'vue-router';

export default [
    {
        path: '/products/:isPodSelectionFlow?',
        name: 'Products',
        component: () => import(/* webpackChunkName: "products" */ '@/views/Products/Products.vue')
    },
    {
        path: '/product/:isPodSelectionFlow?',
        name: 'Product',
        component: () => import(/* webpackChunkName: "products" */ '@/views/Product/Product.vue')
    },
    {
        path: '/sellingGroups',
        name: 'SellingGroups',
        props: true,
        component: () => import(/* webpackChunkName: "products" */ '@/views/Products/SellingGroups.vue')
    }
] as RouteConfig[];
